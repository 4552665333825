<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <!-- <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link> -->
      </li>
    </ul>

    <!-- Left Col -->

    <ul class="nav m-auto d-xl-none" style="padding-left: 54px">
      <li class="nav-item">
        <b-link
          class="navbar-brand mobile d-flex justify-content-between"
          to="/"
        >
          <span class="brand-logo d-flex">
            <b-img :src="appLogoImage" alt="logo" class="h-100" />
          </span>
          <h3
            class="
              brand-text
              align-self-center
              mb-0
              ml-50
              text-primary
              font-weight-bolder
            "
          >
            {{ appName }}
          </h3>
        </b-link>
      </li>
    </ul>

    <b-navbar-nav class="nav align-items-center ml-xl-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p
              class="user-name font-weight-bolder mb-0"
              v-if="userData !== null"
            >
              {{ userData.name }}
            </p>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            v-if="userData !== null"
            :src="baseUrl + `image/user/` + userData.image"
          />
        </template>

        <b-dropdown-item
          :to="{ name: 'profile' }"
          link-class="d-flex align-items-center"
        >
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>Profile</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BImg,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";

import { $themeConfig } from "@themeConfig";

import useJwt from "@/auth/jwt/useJwt";
import { avatarText } from "@core/utils/filter";

export default {
  components: {
    BLink,
    BImg,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      userData: null,
      avatarText,
    };
  },
  setup() {
    // App Name
    const { appName, appLogoImage, baseUrl } = $themeConfig.app;
    return {
      appName,
      appLogoImage,
      baseUrl,
    };
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  created() {
    let $userData = JSON.parse(localStorage.getItem("userData"));

    this.userData = $userData;

    if ($userData === null) {
      this.logout();
    }
  },
  methods: {
    logout() {
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem("userData");
      this.$router.push({ name: "login" });
    },
  },
};
</script>
