export default [
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'GridIcon',
  },
  {
    title: 'Polis',
    route: 'policies',
    icon: 'FileTextIcon',
  },
  {
    title: 'Claim',
    route: 'claims',
    icon: 'FileTextIcon',
  },
]
