<template>
  <div
    v-if="
      permissions.includes('policy-create') ||
      permissions.includes('policy-view') ||
      permissions.includes('claim-view') ||
      permissions.includes('claim-create')
    "
  >
    <div class="clearfix mb-0 text-sm-left text-lg-center">
      <span class="d-block mt-25">
        <div class="d-none d-lg-block">
          <span class="d-block d-lg-inline-block mr-25"
            >COPYRIGHT © {{ new Date().getFullYear() }}</span
          >
          <b-link
            class="d-none d-lg-inline-block"
            href="https://pt-gti.co.id"
            target="_blank"
            >PT. Gracia Teknologi Inovasi</b-link
          >
          <span class="d-none d-lg-inline-block">, All rights Reserved</span>
        </div>
        <div class="d-block d-lg-none">
          <span class="d-block d-lg-block mr-1 float-left"
            ><img
              :src="require('@/assets/images/logo/logo-gti.png')"
              alt=""
              height="30"
          /></span>
          <span class="d-block d-lg-block mr-1 mt-25 float-left"
            ><img
              :src="require('@/assets/images/logo/logo-aswata.png')"
              alt=""
              height="20"
          /></span>
          <span class="d-block d-lg-block mr-25 mt-50 float-left font-small-3"
            >COPYRIGHT © {{ new Date().getFullYear() }}</span
          >
        </div>
      </span>
    </div>
  </div>
  <div class="clearfix mb-0 text-sm-left text-lg-center" v-else>
    <span class="d-block mt-25">
      <span class="d-block d-lg-inline-block mr-25"
        >COPYRIGHT © {{ new Date().getFullYear() }}</span
      >
      <b-link
        class="d-sm-inline-block"
        href="https://pt-gti.co.id"
        target="_blank"
        >PT. Gracia Teknologi Inovasi</b-link
      >
      <span class="d-none d-sm-inline-block">, All rights Reserved</span>
    </span>
  </div>
</template>

<script>
import { BLink } from "bootstrap-vue";

export default {
  components: {
    BLink,
  },
  data() {
    return {
      permissions: null,
    };
  },
  created() {
    var $permissions = JSON.parse(localStorage.getItem("userPermission"));
    this.permissions = $permissions;
  },
};
</script>
